import Layout from "./components/Layout/Layout";

import { Switch, Route } from "react-router";
import Home from "./pages/Home/Home";

function App() {
  return (
    <div className="App">
      <Layout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
