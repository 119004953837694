import React, { useState, useEffect } from "react";
import styles from "./Pricing.module.scss";
import globalStyles from "../../global.module.scss";
import client from "../../services/contentful.js";
import four_bottles from "../../assets/images/4_flaskor.png";
import eight_bottles from "../../assets/images/8_flaskor.png";
import background from "../../assets/images/gtkryddning.png";

const Pricing = () => {
  const [isLoading, setisLoading] = useState(true);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    client.getEntries({ content_type: "priser" }).then((entries) => {
      setPrices(entries.items.reverse());
      setisLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className={styles.container} id="products">
          <div className={styles.bg} style={{ backgroundImage: `URL(${background})` }} />
          <div id="pricing" className={styles.pricing}>
            <div className={`${styles.container} ${globalStyles.max_width}`}>
              <h1 className={styles.title}>Gör din egen Gin</h1>

              <div className={styles.section}>
                <div className={styles.card}>
                  <img src={four_bottles} alt="four bottles" />
                  <h1>598:- per flaska</h1>
                  <h2>4 flaskor a 50cl</h2>
                  <button>Välj</button>
                </div>
                <div className={styles.card}>
                  <img src={eight_bottles} alt="eight bottles" />
                  <h1>498:- per flaska</h1>
                  <h2>8 flaskor a 50cl</h2>
                  <button>Välj</button>
                </div>
                <div className={styles.text}>
                  Hos Ginkällan väljer du själv om du vill destillera 2 eller 4 liter färdig Gin och levereras på 50 cl
                  flaskor där varje flaska bär din helt unika digitala etikett. <br /> <br />
                  För varje flaska väljer du så väl vilken typ så som London Dry, Old Tom, Navy Strength eller Pink Gin
                  du vill att den skall innehålla samt viken alkoholstyrka den skall ha. <br /> <br /> För varje flaska
                  väljer du så väl vilken typ så som London Dry, Old Tom, Navy Strength eller Pink För varje flaska
                  väljer du så väl vilken typ
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pricing;
