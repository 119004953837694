import React, { useState, useEffect } from "react";
import styles from "./Hero.module.scss";
import globalStyles from "../../global.module.scss";
import client from "../../services/contentful.js";
import { HashLink as Link } from "react-router-hash-link";

import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import playBtn from "../../assets/play_btn.png";

const Hero = () => {
  const [isLoading, setisLoading] = useState(true);
  const [heroImage, setHeroImage] = useState("");
  const [hero, setHero] = useState([]);
  const [puffs, setPuffs] = useState([]);
  const [videoPuffs, setVideoPuffs] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    const getHeroContent = () => {
      client.getEntry("5dkPFHTyVuFoHBValTcRul").then((entry) => {
        setHero(entry.fields);
        setHeroImage(entry.fields.backgroundImage.fields.file.url);
        client.getEntries({ content_type: "puff" }).then((entries) => setPuffs(entries.items));
        client.getEntries({ content_type: "videoPuff" }).then((entries) => setVideoPuffs(entries.items));
      });

      setisLoading(false);
    };

    getHeroContent();
  }, []);

  const openVideoPlayer = (id) => {
    setVideoId(id);
    setOpen(true);
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          <div className={styles.content} style={{ backgroundImage: `URL(https:${heroImage})` }}>
            <header className={globalStyles.max_width}>
              <h1>{hero.headline}</h1>
              {/* <p>{hero.subheadline}</p> */}
            </header>
          </div>
          <div className={globalStyles.max_width}>
            <div className={styles.cards}>
              {videoPuffs.map((puff, index) => (
                <div
                  key={index}
                  className={styles.card}
                  style={{
                    backgroundImage:
                      puff.fields.backgroundImage !== undefined
                        ? `URL(https:${puff.fields.backgroundImage.fields.file.url})`
                        : "",
                  }}
                >
                  <h1>{puff.fields.text}</h1>
                  <button className={styles.button} key={index} onClick={() => openVideoPlayer(puff.fields.videoId)}>
                    <img src={playBtn} alt="play" />
                  </button>
                </div>
              ))}
              {puffs.map((puff, index) => (
                <div
                  key={index}
                  className={styles.card}
                  style={{
                    backgroundImage:
                      puff.fields.backgroundImage !== undefined
                        ? `URL(https:${puff.fields.backgroundImage.fields.file.url})`
                        : "",
                  }}
                >
                  <h1>{puff.fields.text}</h1>
                  <Link className={styles.button} to={puff.fields.scrollTo !== undefined ? puff.fields.scrollTo : ""} key={index}>
                    <li>START</li>
                  </Link>
                </div>
              ))}
            </div>
            <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
          </div>
        </>
      )}
    </>
  );
};

export default Hero;
