import React from "react";
import styles from "./Introduction.module.scss";
import globalStyles from "../../global.module.scss";

import heart from "../../assets/heart.svg";
import hand from "../../assets/hand.svg";
import lightbulb from "../../assets/Lightbulb.svg";

const Introduction = () => {
  return (
    <div className={`${styles.container} ${globalStyles.max_width}`} id="intro">
      <ul className={styles.intro_section}>
        <li>
          <img src={lightbulb} alt="heart" />
          <h1>Förverkliga din Gin idé hos Ginkällan</h1>
          <p>
            Hos Ginkällan går du från idé till färdigt Gin via ett par enkla klick. Utgå från redan befintliga recept
            eller skapa din Gin frå ngrunden. Du bestämmer!
          </p>
        </li>
        <li>
          <img src={hand} alt="hand" />
          <h1>Följ destilleringen av din Gin i realtid</h1>
          <p>
            I vårt uppkopplade destilleri kan du följa varje liten droppe Gin som du destillerar. Se hur temperaturen
            stiger i kolonnen och få en god förståelse för hur olika smaker växer fram under processen.
          </p>
        </li>
        <li>
          <img src={heart} alt="lightbulb" />
          <h1>Skapa din egen "Världens bästa Gin"</h1>
          <p>
            Hos Ginkällan har alla möjlighet att skapa sin helt egen "Världens bästa Gin". Utgår du från ett av våra
            redan befintliga recept så har du redan en bra grund att utgå ifrån.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Introduction;
