import React, { useRef } from "react";
import styles from "./Navbar.module.scss";
import globalStyles from "../../global.module.scss";

import { HashLink as Link } from "react-router-hash-link";

import MenuItems from "./MenuItems.js";
import logo from "../../assets/qr-code-logo.png";

const Navbar = () => {
  const mobileMenuRef = useRef();
  const toggleMobileMenu = () =>
    mobileMenuRef.current?.classList.toggle(styles.active);

  return (
    <nav>
      <div className={`${globalStyles.max_width} ${styles.container}`}>
        <img alt="logo" src={logo} className={styles.logo} onClick={() => window.scrollTo(0,0)}/>

        <ul ref={mobileMenuRef}>
          {MenuItems.map((item, index) => (
            <Link
              /* exact */
              to={item.url}
              /* activeClassName={styles.selected} */
              key={index}
            >
              <li>{item.title}</li>
            </Link>
          ))}
        </ul>

        <button className={styles.login}>Logga in</button>
        {/* <button className={styles.cta}>Bli medlem</button> */}

        <div className={styles.mobileMenu} onClick={() => toggleMobileMenu()}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
