import React from "react";
import styles from "./Home.module.scss";
import Hero from "../../components/Hero/Hero";
import Pricing from "../../components/Pricing/Pricing";
import Introduction from "../../components/Introduction/Introduction";
import Navbar from "../../components/Navbar/Navbar";

const Home = () => {
  return (
    <div className={styles.parent}>
      <section className={styles.child}>
        <Navbar />
        <Hero />
      </section>
      <section className={styles.child}>
        <Introduction />
      </section>
      <section className={styles.child}>
        <Pricing />
      </section>
    </div>
  );
};

export default Home;
